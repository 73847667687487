/* eslint-disable react/jsx-filename-extension */
import React from "react"
import { graphql } from "gatsby"
import Layout from "./pageLayout"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import * as basicPageStyles from "./basicPage.module.css"

import ScrollAnimation from "react-animate-on-scroll"
import "animate.css"

export default function projectPage({ pageContext, data }) {
  const nodeData = data.mdx
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const customCrumbLabel = nodeData.frontmatter.title

  return (
    <Layout title={nodeData.frontmatter.title}>
      <div className={basicPageStyles.container}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" > "
          crumbLabel={customCrumbLabel}
        />
        <ScrollAnimation animateIn="animate__fadeIn" animateOnce={true}>
          <h1 style={{ textTransform: "uppercase" }}>
            {nodeData.frontmatter.title}
          </h1>
        </ScrollAnimation>
        <MDXProvider>
          <MDXRenderer
            frontmatter={nodeData.frontmatter}
            styles={basicPageStyles}
          >
            {nodeData.body}
          </MDXRenderer>
        </MDXProvider>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      body
      frontmatter {
        type
        title
        img {
          childImageSharp {
            fluid(maxWidth: 886, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
